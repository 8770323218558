import { AppState } from './store';
import { createSlice } from '@reduxjs/toolkit';

import { HYDRATE } from 'next-redux-wrapper';
import { Ui } from 'type/ui';

// Type for our state
export type UiState = {
  ui: Ui | null;
};

// Actual Slice
export const uiSlice = createSlice({
  name: 'ui',
  initialState: {} as any,

  reducers: {
    setUi(state, action) {
      state.ui = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase<any>(HYDRATE, (state, action) => ({
      ...state,
      ...action.payload,
    }));
  },
});

export const { setUi } = uiSlice.actions;

export const selectUiState = (state: AppState): Ui => state.ui.ui;

export default uiSlice.reducer;
